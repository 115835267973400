import React, { act, FC, useEffect, useState } from 'react'
import styles from './PlayerModule.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import useApiRequest from '../../hooks/UseApiRequestState';
import PlayerService from './api/PlayerService';
import Loader from '../../UI/Loader/Loader';
import PageLayout from '../../layouts/PageLayout/PageLayout';
import MyInput from '../../UI/MyInput/MyInput';
import { IUser, RoleType } from '../../models/IUser';
import MySelect from '../../UI/MySelect/MySelect';
import { playerTypes } from '../../constants/playerTypes';
import HeaderButton from '../../UI/HeaderButton/HeaderButton';
import saveIcon from '../../assets/images/edit-training.svg';
import addIcon from '../../assets/images/commit-training.svg';
import useErrorStore from '../../components/Errors/store/error';
import { useAuthStore } from '../../app/store/auth';
import { SelectOption } from '../../models/SelectOption';
import PopUpMenu from '../../UI/PopUpMenu/PopUpMenu';
import removeIcon from '../../assets/images/delete-bin.svg'
import { RouteNames } from '../../app/router';
import { IApliacation } from '../../models/IAplication';
import { UserApplications } from './components/UserApplications/UserApplications';
import MyButton from '../../UI/MyButton/MyButton';
import { ChangePassword } from './components/ChangePassword/ChangePassword';

interface PlayerModuleProps {
    action: 'edit' | 'create'
}
const PlayerModule: FC<PlayerModuleProps> = ({ action }) => {
    const { id } = useParams();
    const setError = useErrorStore(store => store.setError)
    //const { data: usersData, error, isLoading, execute } = useApiRequest(PlayerService.getUser);
    const role = useAuthStore(store => store.role)
    const userId = useAuthStore(store => store.userId)

    const navigate = useNavigate()
    const [user, setUser] = useState<IUser>({
        id: 0,
        username: '',
        role: '',
        email: '',
        adminComment: '',
        phone: ''
    });

    const [validationError, setValiedationError] = useState({
        username: false,
        role: false,
        email: false,
        adminComment: false,
/*         phone: false */
    })


    const validateUser = (): boolean => {
        const errors = {
            username: !user.username.trim(),
            role: !user.role.trim(),
            email: false,
     /*        phone: !user.phone.trim(), */
            adminComment: false, // Не обязательно, всегда false
        };

        setValiedationError(errors);

        // Возвращает true, если нет ошибок, иначе false
        return !Object.values(errors).some((isError) => isError);
    };

  
    const fetchUser = async () => {
        const {data} = await PlayerService.getUser(Number(id))
        setUser(data)
    }
    useEffect(() => {
        if (action == 'edit') fetchUser()

    }, []);

    const [successCreation, setSuccessCretion] = useState<boolean>(false);
    const [returnedPassword, setReturnedPassword] = useState<string>('');

    const [newPlayerId, setNewPlayerId] = useState<number>(0);
    const createPlayer = async () => {
        if (validateUser()) {
            const createFunction = user.role === 'trainer'
                ? PlayerService.createTrainer
                : PlayerService.createUser;
                userId
            const { data: newPlayerData } = await createFunction(user);

            setReturnedPassword(newPlayerData.password);
            setSuccessCretion(true);
            setNewPlayerId(newPlayerData.id);
        }
    }

    const handleSuccessMenuClose = () => {
        navigate('/player/' + newPlayerId);
    }
    const handleAction = async () => {
        try {
            if (action == 'edit') await PlayerService.editUser(user)
            if (action == 'create') await createPlayer()
        } catch (error: any) {
            setError(error)
        }
    }


    const [showDeleteUserMenu, setShowDeleteUserMenu] = useState<boolean>(false);
    const handleDeleteUser = async () => {
        try {
            await PlayerService.deleteUser(user.id)
            navigate(RouteNames.PLAYERS)
        } catch (error: any) {
            setError(error)
        }
    }

    
    return (
        <PageLayout
            title={action == 'create' ? 'Neuer Spieler hinzufügen' : user.username}
            headereComopnent={
                <div className={styles.headerRow}>
                    {role == 'admin' &&
                        <>
                            {action == 'edit' &&
                                <HeaderButton icon={removeIcon} onClick={() => setShowDeleteUserMenu(true)} />
                            }
                            <HeaderButton icon={action == 'create' ? addIcon : saveIcon} onClick={handleAction} />
                        </>}
                </div>
            }
        >
            <div className={styles.main}>
                <div className={styles.row}>
                    <div className={styles.inputRow}>
                        <div className={styles.inputTitle}>Benutzername</div>
                        <MyInput className={styles.input} type='text' placeholder='Benutzername eingeben'
                            value={user.username} setValue={value => setUser({ ...user, username: value })}
                            disabled={role !== 'admin'}
                            hasError={validationError.username}
                        />
                    </div>
                    <div className={styles.inputRow}>
                        <div className={styles.inputTitle}>Telefonnummer</div>
                        <MyInput className={styles.input} type='text' placeholder='Telefonnummer eingeben'
                            value={user.phone} setValue={value => setUser({ ...user, phone: value })}
                            disabled={role !== 'admin'} /* hasError={validationError.phone} */

                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.inputRow}>
                        <div className={styles.inputTitle}>Email</div>
                        <MyInput className={styles.input}
                            type='text' placeholder='Email eingeben'
                            disabled={role !== 'admin'} hasError={validationError.email}
                            value={user.email} setValue={value => setUser({ ...user, email: value })}
                        />
                    </div>
                    {user.role !== 'admin' &&
                        <div className={styles.inputRow}>
                            <div className={styles.inputTitle}>Benutzerstatus</div>
                            <MySelect className={styles.input}
                                /*  disabled={role !== 'admin'} */
                                options={playerTypes} value={user.role} onChange={value => setUser({ ...user, role: value as RoleType })}
                                placeholder='Benutzerstatus wählen' hasError={validationError.role}
                            />
                        </div>
                    }

                </div>

                <PopUpMenu
                    title='Benutzer erfolgreich erstellt'
                    show={successCreation}
                    setShow={setSuccessCretion}
                    closeButtonTitle='Weiter'
                    onCloseButtonClick={handleSuccessMenuClose}
                    size='big'
                    disappear = {role == 'trainer' ? -1: 1.5}
                >
                    <div className={styles.inputTitle}>{user.role == 'trainer' ? `Passwort für die Kontoanmeldung: ${user.role == 'trainer' && returnedPassword}`: '' }</div>
                </PopUpMenu>


                <PopUpMenu
                    title="Benutzer löschen"
                    submitButtonTitle="Löschen"
                    closeButtonTitle="Zurück"
                    onSubmit={handleDeleteUser}
                    show={showDeleteUserMenu}
                    setShow={setShowDeleteUserMenu}

                >
                    <div className={styles.popUpText}>Möchten Sie diesen Benutzer wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden</div>
                </PopUpMenu>

                <div className={styles.inputRow}>

                    <div className={styles.inputTitle}>Kommentar des Administrators</div>
                    <textarea className={`${styles.input} ${styles.textarea}`} value={user.adminComment} onChange={e => setUser({ ...user, adminComment: e.target.value })}
                        disabled={role !== 'admin'}
                        placeholder='Kommentar des Administrators eingeben'
                    ></textarea>
              
                    {((role  == 'admin' || userId == user.id) && (user.role == 'admin' || user.role == 'trainer') && action == 'edit')&&
                    <ChangePassword/>
                    }
              
                </div>

                {user.role !== 'trainer' && user.role !== 'admin' && action == 'edit' &&
                    <UserApplications userId={Number(id)}/>
                }
            </div>
        </PageLayout>
    )
}

export default PlayerModule